<template>
  <div class="assignment-actions-container">
    <div class="form-group">
      <b>Tipul comenzii</b>
      <v-select
        v-model="selectedCmd"
        :options="cmds"
        :disabled="announcement || verbalProcess ? true : false"
      >
      </v-select>
    </div>
    <div class="assignment-actions">
      <div v-if="selectedCmd && selectedCmd.value == 'cmd1'" class="form-group">
        <b>Achiziție din catalogul S.E.A.P</b>
        <div>
          <div class="assignment-actions-btns">
            <button
              v-if="!paapCentralization.procedureType.socialOnly"
              class="Button1 Btn_center"
              @click="openModal('cmd1')"
            >
              <span class="Btn_content">CMD1</span>
            </button>

            <button
              v-if="paapCentralization.procedureType.socialOnly"
              class="Button1 Btn_center"
              @click="openModal('cmd1_a2')"
            >
              <span class="Btn_content">CMD1_A2</span>
            </button>

            <button class="Button1 Btn_center" @click="openModal('cmd31')">
              <span class="Btn_content">CMD31</span>
            </button>
          </div>
          <div class="assignment-actions-btns" style="margin-top: 5px;">
            <button
              v-if="!paapCentralization.procedureType.socialOnly"
              class="Button1 Btn_center"
              @click="openModal('crt1')"
            >
              <span class="Btn_content">CTR1</span>
            </button>
            <button
              v-if="paapCentralization.procedureType.socialOnly"
              class="Button1 Btn_center"
              @click="openModal('crt1_a2')"
            >
              <span class="Btn_content">CTR1_A2</span>
            </button>
            <button class="Button1 Btn_center" @click="openModal('crt31')">
              <span class="Btn_content">CTR31</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="selectedCmd && selectedCmd.value == 'cmd2'" class="form-group">
        <b>Achiziție prin invitație de participare</b>
        <div v-if="!announcement" class="assignment-actions-btns">
          <button class="Button1 Btn_center" @click="createAnnouncement()">
            <span class="Btn_content">ANUNȚ</span>
          </button>
        </div>

        <div v-if="announcement">
          <div class="assignment-actions-btns">
            <button
              v-if="!paapCentralization.procedureType.socialOnly"
              class="Button1 Btn_center"
              @click="createAcquisitionForAnnouncement('cmd2')"
            >
              <span class="Btn_content">CMD2</span>
            </button>
            <button
              v-if="paapCentralization.procedureType.socialOnly"
              class="Button1 Btn_center"
              @click="createAcquisitionForAnnouncement('cmd2_a2')"
            >
              <span class="Btn_content">CMD2_A2</span>
            </button>
            <button
              v-if="!paapCentralization.procedureType.socialOnly"
              class="Button1 Btn_center"
              @click="createAcquisitionForAnnouncement('crt2')"
            >
              <span class="Btn_content">CRT2</span>
            </button>

            <button
              v-if="paapCentralization.procedureType.socialOnly"
              class="Button1 Btn_center"
              @click="createAcquisitionForAnnouncement('crt2_a2')"
            >
              <span class="Btn_content">CRT2_A2</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="selectedCmd && selectedCmd.value == 'cmd3'" class="form-group">
        <b>Achiziție în baza ofertelor</b>
        <div v-if="!verbalProcess" class="assignment-actions-btns">
          <button
            class="Button1 Btn_center"
            @click="createVerbalProcess('verbal_process')"
          >
            <span class="Btn_content">PV</span>
          </button>
        </div>
        <div v-if="verbalProcess">
          <div class="assignment-actions-btns">
            <button
              v-if="!paapCentralization.procedureType.socialOnly"
              @click="createAcquisitionForVerbalProcess('cmd3')"
              class="Button1 Btn_center"
            >
              <span class="Btn_content">CMD3</span>
            </button>
            <button
              v-if="paapCentralization.procedureType.socialOnly"
              @click="createAcquisitionForVerbalProcess('cmd3_a2')"
              class="Button1 Btn_center"
            >
              <span class="Btn_content">CMD3_A2</span>
            </button>
            <button
              v-if="!paapCentralization.procedureType.socialOnly"
              @click="createAcquisitionForVerbalProcess('crt3')"
              class="Button1 Btn_center"
            >
              <span class="Btn_content">CRT3</span>
            </button>
            <button
              v-if="paapCentralization.procedureType.socialOnly"
              @click="createAcquisitionForVerbalProcess('crt3_a2')"
              class="Button1 Btn_center"
            >
              <span class="Btn_content">CRT3_A2</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="selectedCmd && selectedCmd.value == 'cmd4'" class="form-group">
        <b>Achizițieîn baza angajamentului legal</b>
        <div class="assignment-actions-btns">
          <button class="Button1 Btn_center" @click="openModal('cmd4')">
            <span class="Btn_content">CMD4</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DialogModal from "@/components/DialogModal";
import Command1 from "@/components/Command1";
import VerbalProcess from "@/components/VerbalProcess";
import Announcement from "@/components/Announcement";
import { ACQUISITIONS, VERBAL_PROCESS, ANNOUNCEMENT } from "../api.js";

export default {
  props: {
    parentTarget: { type: String, required: true },
    centralization: { type: Object, default: () => null },
    needReferences: { type: String | Array, default: () => null },
    verbalProcess: { type: Object, default: () => null },
    announcement: { type: Object, default: () => null },
  },

  data() {
    return {
      selectedCmd: null,
      cmds: [
        { value: "cmd1", label: "Achiziție din catalogul S.E.A.P" },
        { value: "cmd2", label: "Achiziție prin invitație de participare" },
        { value: "cmd3", label: "Achiziție în baza ofertelor" },
      ],
    };
  },
  computed: {
    paapCentralization() {
      if (this.centralization) return this.centralization;
      if (this.announcement) return this.announcement.paapCentralization;
      if (this.verbalProcess) return this.verbalProcess.paapCentralization;
    },
  },

  methods: {
    openModal(type) {
      this.$modal.hide(this.parentTarget);

      this.$modal.show(
        DialogModal,
        {
          target: type,
          title: this.acquisitionTitle[type],
          inputs: [
            {
              id: "cmd",
              type: "component",
              component: Command1,
              componentProps: { acqType: type },
            },
          ],
          acceptFinish(data) {
            const cmd = data.cmd;
            for (const key in cmd) {
              if (!cmd[key]) {
                this.$toastr.w("Nu au fost completate toate secțiunile.");
                return false;
              }
            }
            return true;
          },
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Salvează",
            handler: (data) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Salvarea achiziției a eșuat.");
                this.setLoad();
              };
              this.setLoad(true);
              ACQUISITIONS.add({
                ...data.cmd,
                acqType: type,
                needReferences: this.needReferences,
                paapCentralization: this.centralization,
              })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.setLoad();
                    this.$toastr.s("Achiziția a fost salvată.");
                    const route = this.$route.path + "/achizitii";
                    this.$router.push({
                      path: route,
                      query: { aq: res.data.result.id },
                    });
                  } else {
                    error(res.response);
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: type,
          adaptive: true,
          width: "650px",
          height: "570px",
        }
      );
    },

    createVerbalProcess(type) {
      this.$modal.hide(this.parentTarget);

      this.$modal.show(
        DialogModal,
        {
          target: type,
          title: "Proces verbal de evaluare al ofertelor",
          inputs: [
            {
              id: "pv",
              type: "component",
              component: VerbalProcess,
              errReason: "Nu au fost completate toate secțiunile.",
              componentProps: {},
            },
          ],
          acceptFinish(data) {
            const pv = data.pv;
            for (const key in pv) {
              if (!pv[key]) {
                this.$toastr.w("Nu au fost completate toate secțiunile.");
                return false;
              }
              if (key == "memberRole") {
                if (pv[key].length < 2) {
                  this.$toastr.w("Nu au fost completate toate secțiunile.");
                  return false;
                }

                for (let i = 0; i < pv[key].length; i++) {
                  if (!pv[key][i]) {
                    this.$toastr.w("Nu au fost completate toate secțiunile.");
                    return false;
                  }
                }
              }
            }
            return true;
          },
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Salvează",
            handler: (data) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Salvarea procesului verbal a eșuat.");
                this.setLoad();
              };

              this.setLoad(true);

              VERBAL_PROCESS.add({
                ...data.pv,
                needReferences: this.needReferences,
                paapCentralization: this.centralization,
              })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.setLoad();
                    this.$toastr.s("Procesul verbal a fost salvat.");
                    const route = this.$route.path + "/proces-verbal";
                    this.$router.push({
                      path: route,
                    });
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
          customClass: "verbal-process-modal",
        },
        {
          name: type,
          adaptive: true,
          width: "1000px",
          height: "1000px",
        }
      );
    },

    createAnnouncement() {
      this.$modal.hide(this.parentTarget);

      this.$modal.show(
        DialogModal,
        {
          target: "CreateAnnouncement",
          title: "Anunț",
          inputs: [
            {
              id: "announcement",
              type: "component",
              component: Announcement,
              errReason: "Nu au fost completate toate secțiunile.",
              componentProps: {},
            },
          ],
          acceptFinish(data) {
            const announcement = data.announcement;

            for (const key in announcement) {
              if (!announcement[key]) {
                this.$toastr.w("Nu au fost completate toate secțiunile.");
                return false;
              }
            }
            return true;
          },

          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Salvează",
            handler: (data) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Salvarea anunțului a eșuat.");
                this.setLoad();
              };

              this.setLoad(true);
              ANNOUNCEMENT.add({
                ...data.announcement,
                needReferences: this.needReferences,
                paapCentralization: this.centralization,
              })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.setLoad();
                    this.$toastr.s("Anunțul a fost salvat.");
                    const route = this.$route.path + "/anunt";
                    this.$router.push({
                      path: route,
                    });
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
          customClass: "verbal-process-modal",
        },
        {
          name: "CreateAnnouncement",
          adaptive: true,
          width: "1000px",
          height: "650px",
        }
      );
    },

    createAcquisitionForVerbalProcess(type) {
      this.$modal.hide(this.parentTarget);

      this.$modal.show(
        DialogModal,
        {
          target: "CreateAcquisitionVerbalProduct",
          title: this.acquisitionTitle[type],
          inputs: [
            {
              id: "cmd",
              type: "component",
              component: Command1,
              componentProps: {
                verbalProcess: this.verbalProcess,
                acqType: type,
              },
            },
          ],
          acceptFinish(data) {
            const cmd = data.cmd;
            for (const key in cmd) {
              if (!cmd[key]) {
                this.$toastr.w("Nu au fost completate toate secțiunile.");
                return false;
              }
            }
            return true;
          },
          closeBtnText: "Anulează",

          button: {
            type: 2,
            value: "Crează",
            handler: (data) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Crearea a eșuat.");
                this.reload();
                this.setLoad();
              };

              this.setLoad(true);
              VERBAL_PROCESS.createAcquisition(this.verbalProcess.id, {
                ...data.cmd,
                acqType: type,
              })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.setLoad();

                    this.$toastr.s("Crearea achiziției a fost efectuată.");

                    const path = this.$route.path;
                    this.$router.push({
                      path: path.replace("proces-verbal", "achizitii"),
                      query: { aq: res.data.result.id },
                    });
                  } else {
                    error(res.response);
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "CreateAcquisitionVerbalProduct",
          adaptive: true,
          width: "650px",
          height: "570px",
        }
      );
    },
    createAcquisitionForAnnouncement(type) {
      this.$modal.hide(this.parentTarget);
      this.$modal.show(
        DialogModal,
        {
          target: "CreateAcquisitionAnnouncement",
          title: this.acquisitionTitle[type],
          inputs: [
            {
              id: "cmd",
              type: "component",
              component: Command1,
              componentProps: {
                announcement: this.announcement,
                acqType: type,
              },
            },
          ],
          acceptFinish(data) {
            const cmd = data.cmd;
            for (const key in cmd) {
              if (!cmd[key]) {
                this.$toastr.w("Nu au fost completate toate secțiunile.");
                return false;
              }
            }
            return true;
          },
          closeBtnText: "Anulează",

          button: {
            type: 2,
            value: "Crează",
            handler: (data) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Crearea a eșuat.");
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(true);
              ANNOUNCEMENT.createAcquisition(this.announcement.id, {
                ...data.cmd,
                acqType: type,
              })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.setLoad();

                    this.$toastr.s("Crearea achiziției a fost efectuată.");

                    const path = this.$route.path;
                    this.$router.push({
                      path: path.replace("anunt", "achizitii"),
                      query: { aq: res.data.result.id },
                    });
                  } else {
                    error(res.response);
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "CreateAcquisitionAnnouncement",
          adaptive: true,
          width: "650px",
          height: "570px",
        }
      );
    },
  },

  created() {
    if (this.verbalProcess) {
      this.selectedCmd = {
        value: "cmd3",
        label: "Achiziție în baza ofertelor",
      };
    } else if (this.announcement) {
      this.selectedCmd = {
        value: "cmd2",
        label: "Achiziție prin invitație de participare",
      };
    }

    if (this.centralization && !this.centralization.procedureType.socialOnly) {
      this.cmds.push({
        value: "cmd4",
        label: "Achiziție în baza angajamentului legal",
      });
    }
  },
};
</script>
<style lang="scss">
.assignment-actions-container {
  max-width: 900px;

  .assignment-actions {
    flex: 1;
    .Button1 {
      border: 0;
      margin: 3px;
    }
    &-btns {
      border: 1px solid;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }
  .assignment-actions-info_container {
    flex: 0.5;
    .assignment-actions-info {
      border: 1px solid;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      padding: 10px;
    }
  }
}
.verbal-process-modal {
  max-width: 100% !important;
  max-height: 100% !important;
}
</style>
