<template>
  <div>
    <div class="verbal-process-group">
      <div class="form-group">
        <b>Data procesului verbal</b>
        <date-picker
          v-model="verbalProcess.verbalProcessDate"
          type="date"
          format="DD-MM-YYYY"
          valueType="date"
          popup-class="bigger-index"
          :disabled="true"
        />
      </div>
      <div class="form-group">
        <b>Criteriul de atribuire</b>
        <v-select
          v-model="verbalProcess.attributionCriteria"
          :options="[
            'Preţul cel mai scăzut',
            'Costul cel mai scăzut',
            'Cel mai bun raport calitate-preţ',
            'Cel mai bun raport calitate-cost',
          ]"
        >
        </v-select>
      </div>
    </div>

    <div class="verbal-process-group">
      <div class="form-group">
        <b
          >Modalitatea de solicitare / obținere oferte financiare și tehnice:</b
        >

        <v-select
          v-model="verbalProcess.requestModality"
          :options="infoModality"
        >
        </v-select>
      </div>
      <div class="form-group">
        <b>Modalitatea de primire / obținere oferte financiare și tehnice:</b>

        <v-select
          v-model="verbalProcess.receiveModality"
          :options="infoModality"
        >
        </v-select>
      </div>
    </div>
    <div class="verbal-process-group">
      <div class="form-group">
        <b>Reasponsabil procedură (Membru 1):</b>
        <input style="width:100%" :value="member1" :disabled="true" />
      </div>
    </div>
    <div class="verbal-process-group">
      <div class="form-group">
        <b>Reasponsabil procedură (Membru 2):</b>
        <v-select
          v-model="verbalProcess.memberRole[0]"
          :options="memberRolesView"
        >
        </v-select>
      </div>
      <div class="form-group">
        <b>Reasponsabil procedură (Membru 3):</b>
        <v-select
          v-model="verbalProcess.memberRole[1]"
          :options="memberRolesView"
        >
        </v-select>
      </div>
    </div>

    <div>
      <div
        v-for="(item, index) of verbalProcess.verbalProcessInstitutionProvider"
      >
        <b>Oferta {{ index + 1 }} </b>

        <div class="verbal-process-container">
          <i
            @click="deleteProvider(index)"
            v-tooltip="'Șterge furnizor'"
            class="fas fa-minus-circle delete-provider-icon"
            style="cursor: pointer;  color: red"
          ></i>
          <div class="verbal-process-group">
            <div class="verbal-process">
              <div>
                <b>CUI furnizor</b>
              </div>
              <div class="RForm">
                <input
                  type="text"
                  class="vInput"
                  v-model="item.institutionProviderCui"
                  @keydown.tab="getByCui(index)"
                />
                <i @click="getByCui(index)" class="fas fa-search"></i>
              </div>
            </div>
            <div class="form-group noMB">
              <b>Nume furnizor</b>
              <input
                type="text"
                class="vInput form-disabled"
                v-model="item.institutionProviderName"
                :disabled="true"
              />
            </div>
          </div>
          <div class="verbal-process-group" style="margin-top: 10px;">
            <div class="form-group noMB">
              <b>Valoare totală fără TVA</b>
              <CustomNumberInput
                v-model="item.totalValueWithoutTva"
                @input="updateTotalValueWithTva(index)"
              ></CustomNumberInput>
            </div>

            <div class="form-group noMB">
              <b>Cotă TVA</b>
              <input
                min="1"
                max="100"
                type="text"
                class="vInput procent"
                v-model="item.tvaValue"
                @input="($event) => updateTva($event, index)"
              />
            </div>

            <div class="form-group noMB">
              <b>Valoare totală cu TVA</b>
              <CustomNumberInput
                v-model="item.totalValueWithTva"
                :disabled="true"
              ></CustomNumberInput>
            </div>
          </div>
          <div style="margin-top: 8px;">
            <b>ANEXE</b>
            <br />
            <label
              class="input-file-computer-label"
              :for="'verbal_process_' + index"
            >
              <i class="fas fa-laptop"></i>
              Computer
            </label>
            <input
              :id="'verbal_process_' + index"
              type="file"
              style="display: none;"
              @change="(event) => onUploadFiles(event.target.files, index)"
            />
          </div>

          <div style="margin-top: 10px;">
            <div
              v-for="(doc, j) in item.annexes"
              :key="j"
              style="display: flex; align-items: center; justify-content: space-between;"
            >
              <div>{{ doc.key }}</div>
              <div
                style="display: flex; justify-content: flex-end; cursor: pointer;"
              >
                <div style="display: flex;align-items: center;">
                  <i
                    v-tooltip="{ content: 'Deschide fișier' }"
                    style="color: #337ab7;"
                    class="fas fa-external-link-alt"
                    @click="viewOffer(doc)"
                  ></i>
                </div>
                <div
                  v-if="verbalProcess.status == 'draft'"
                  style="margin: 0px 10px;"
                >
                  |
                </div>
                <div
                  v-if="verbalProcess.status == 'draft'"
                  style="display: flex;align-items: center; cursor: pointer;"
                >
                  <i
                    v-tooltip="{ content: 'Șterge fișier' }"
                    style="color: #ec4a4a;"
                    class="fas fa-trash"
                    @click="deleteOffer(index, item.id, j, doc)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="verbalProcess.verbalProcessInstitutionProvider.length < 10"
      style="display: flex; justify-content: flex-end;"
      @click="addProvider"
    >
      <i
        v-tooltip="'Adaugă furnizor'"
        class="fas fa-plus-circle"
        style="cursor: pointer; color: #39f"
      ></i>
    </div>
  </div>
</template>
<script>
import DatePicker from "@/mixins/DatePicker.js";
import CustomNumberInput from "@/components/CustomNumberInput";
import {
  USERS_INSTITUTION,
  INSTITUTION_PROVIDERS,
  VERBAL_PROCESS,
} from "../api.js";
import { v4 as uuidv4 } from "uuid";
import { mapMutations } from "vuex";

export default {
  mixins: [DatePicker],
  components: { CustomNumberInput },
  props: {
    initial: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      member1: null,
      memberRoles: [],
      infoModality: [
        "E-mail/Telefonic/Fax/Adrese",
        "Studiu de piață/Cataloage/Internet",
        "Catalog electronic S.E.A.P",
      ],
      verbalProcess: {
        status: "draft",
        verbalProcessDate: new Date(),
        requestModality: "",
        receiveModality: "",
        attributionCriteria: "",
        memberRole: [],
        verbalProcessInstitutionProvider: [
          {
            totalValueWithoutTva: "",
            tvaValue: "",
            totalValueWithTva: "",
            institutionProviderName: "",
            institutionProviderCui: "",
            annexes: null,
          },
        ],
      },
    };
  },

  watch: {
    verbalProcess: {
      handler(val) {
        this.$emit("input", {
          ...val,
          verbalProcessInstitutionProvider: val.verbalProcessInstitutionProvider.map(
            (el) => ({
              ...el,
              tvaValue: this.removePercentage(el.tvaValue),
            })
          ),
        });
      },
      deep: true,
      immediate: true,
    },
    deep: true,
    immediate: true,
  },
  computed: {
    memberRolesView() {
      return this.memberRoles.filter(
        (el) =>
          el._userId != this.verbalProcess.memberRole[0]?._userId &&
          el._userId != this.verbalProcess.memberRole[1]?._userId &&
          el.id != this.getUserRole(true)
      );
    },
  },
  methods: {
    ...mapMutations(["addWindow"]),

    async createBase64Image(fileObject) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileObject);
      });
    },
    async onUploadFiles(files, index) {
      for (const file of files) {
        const fileType = file.type.split("/");

        const key = `${file.size}-${uuidv4()}.${fileType[fileType.length - 1]}`;

        const base64 = await this.createBase64Image(file);

        if (
          !this.verbalProcess.verbalProcessInstitutionProvider[index].annexes
        ) {
          this.verbalProcess.verbalProcessInstitutionProvider[index].annexes = [
            { key, base64, file },
          ];
        } else {
          this.verbalProcess.verbalProcessInstitutionProvider[
            index
          ].annexes.push({ key, base64, file });
        }
      }

      this.$forceUpdate();
    },
    getByCui(index) {
      const institutionProviderCui = this.verbalProcess
        .verbalProcessInstitutionProvider[index].institutionProviderCui;

      const error = (msg) => {
        this.$toastr.e(msg || "Furnizorul nu a fost gasit.");
        this.verbalProcess.verbalProcessInstitutionProvider[
          index
        ].institutionProviderName = "";
        this.setLoad(false);
      };

      this.setLoad(true);

      INSTITUTION_PROVIDERS.getByCui(institutionProviderCui)
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.verbalProcess.verbalProcessInstitutionProvider[
              index
            ].institutionProviderName = res.data.name;
            this.setLoad(false);
          } else {
            error(res?.data?.response || null);
          }
        })
        .catch(error);
    },

    updateTva(event, index) {
      let tvaValue = this.verbalProcess.verbalProcessInstitutionProvider[index]
        .tvaValue;

      if (event.inputType == "deleteContentBackward" && tvaValue) {
        tvaValue = tvaValue.slice(0, -1);
        tvaValue += "%";
      } else {
        event.target.value = this.removePercentage(event.target.value);

        tvaValue = event.target.value.match(/^\d+\.?\d{0,2}$/);

        if (tvaValue) {
          tvaValue += "%";
        }
      }

      this.verbalProcess.verbalProcessInstitutionProvider[
        index
      ].tvaValue = tvaValue;

      this.updateTotalValueWithTva(index);
    },

    updateTotalValueWithTva(index) {
      let tvaValue = this.verbalProcess.verbalProcessInstitutionProvider[index]
        .tvaValue;

      const totalValueWithoutTva = this.verbalProcess
        .verbalProcessInstitutionProvider[index].totalValueWithoutTva;

      let totalValueWithTva =
        (Number(this.removePercentage(tvaValue)) / 100) *
          Number(totalValueWithoutTva) +
          Number(totalValueWithoutTva) ?? 0;

      this.verbalProcess.verbalProcessInstitutionProvider[
        index
      ].totalValueWithTva = totalValueWithTva;
    },

    removePercentage(val) {
      if (!val) return "";
      return val.replace("%", "");
    },

    deleteProvider(idenx) {
      this.verbalProcess.verbalProcessInstitutionProvider.splice(idenx, 1);
    },
    addProvider() {
      this.verbalProcess.verbalProcessInstitutionProvider.push({
        totalValueWithoutTva: "",
        tvaValue: "",
        totalValueWithTva: "",
        institutionProviderName: "",
        institutionProviderCui: "",
      });
    },

    async getUsers() {
      const role = await this.getBeautifiedRole();

      const name = await this.getUserName();

      const u = await this.getToken();

      this.member1 = `${name} / ${u.id} - ${role}`;

      USERS_INSTITUTION()
        .then((res) => {
          if (Array.isArray(res?.data?.result)) {
            this.memberRoles = res.data.result
              .flatMap(
                (e) =>
                  e?.userRoles?.map((i) => {
                    if (i && i.role == "responsabil_achizitii") {
                      return {
                        ...i,
                        id: i.id ?? this.makeid(6),
                        _userId: e.id,
                        label:
                          this.readUserObject(e) +
                          (i.department?.name
                            ? ` — ${i.department?.name}`
                            : "") +
                          (i.role ? ` — ${this.changeRoleView(i.role)}` : ""),
                      };
                    }
                    return null;
                  }) || []
              )
              .filter((el) => el)
              .sort((a, b) => a.label - b.label);
          }
        })
        .catch();
    },

    async deleteOffer(index, offerId, offerIndex, annexe) {
      if (annexe.base64) {
        this.verbalProcess.verbalProcessInstitutionProvider[
          index
        ].annexes.splice(offerIndex, 1);
      } else {
        const error = (msg) => {
          this.$toastr.e(msg || "Documentul nu a fost șters.");
          this.setLoad();
        };

        this.setLoad(true);
        await VERBAL_PROCESS.deleteOfferAnnexe(this.initial.id, offerId, {
          fileKey: annexe.key,
        })
          .then((res) => {
            if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
              this.$toastr.s("Documentul a fost șters.");

              this.verbalProcess.verbalProcessInstitutionProvider[
                index
              ].annexes.splice(offerIndex, 1);
              this.setLoad();
            } else {
              error(res.response);
            }
          })
          .catch(error);
      }
      this.$forceUpdate();
    },

    viewOffer(annexe) {
      if (!this.initial) {
        this.$toastr.w("Fișierul poate fi vizualizat după salvare.");
      }
      this.addWindow(
        Object.assign({
          fileList: [annexe.key],
          itemId: this.initial.id,
          canDelete: false,
          modal: 13,
          select: 0,
          searchModuleApi: VERBAL_PROCESS.getOfferAnnexe,
          isBlob: annexe.file
        })
      );
    },
  },
  created() {
    this.getUsers();

    if (this.initial) {
      this.verbalProcess = {
        ...this.initial,
        verbalProcessDate: new Date(this.initial.verbalProcessDate),
      };

      this.verbalProcess.memberRole = this.verbalProcess.memberRole.flatMap(
        (e) => {
          return {
            ...e,
            id: e.id ?? this.makeid(6),
            _userId: e.user.id,
            label:
              this.readUserObject(e.user) +
              (e.department?.name ? ` — ${e.department?.name}` : "") +
              (e.role ? ` — ${this.changeRoleView(e.role)}` : ""),
          };
        }
      );

      this.verbalProcess.verbalProcessInstitutionProvider = this.verbalProcess.verbalProcessInstitutionProvider.map(
        (el) => {
          return {
            ...el,
            tvaValue: el.tvaValue + "%",
            institutionProviderName: el.institutionProvider.name,
            institutionProviderCui: el.institutionProvider.cui,
          };
        }
      );
    }
  },
};
</script>
<style lang="scss">
.verbal-process-group {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-group {
    flex: 1;
  }
}
.verbal-process-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  margin-bottom: 10px;
  .delete-provider-icon {
    display: none;
    position: absolute;
    top: -8px;
    right: -7px;
    background-color: white;
  }
}

.verbal-process-container:hover {
  .delete-provider-icon {
    display: initial;
  }
}
.input-file-computer-label {
  background-color: #5bc0de;
  color: white;
  width: fit-content;
  padding: 2px 5px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}
</style>
